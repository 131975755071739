<template>
  <div>
    <v-row class="mb-4">
      <v-col cols="12" class="pa-0">
        <div>
          <v-img
            src="@/assets/CE/pic3.jpg"
            class="pa-0"
            style="
              opacity: 0.8;
              position: relative;
              display: block;
              margin-left: auto;
              margin-right: auto;
              filter: brightness(40%);
            "
            height="151"
            width="414"
          />
          <div style="position: absolute; left: 40%; top: 8%">
            <div
              style="position: relative; left: -40%; top: -8%"
              class="white--text text-center fn16"
            >
              Feline Atopic Syndrome (FAS) : อาการและแนวทางการวินิจฉัย
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <div class="pa-0 overflow-auto" style="height: 490px">
      <v-row no-gutters class="pa-0">
        <v-col cols="12" v-for="(question, index) in questions" :key="index">
          <div class="fn16" style="color: #081f32; font-weight: 600">
            {{ question.no }}
          </div>
          <span class="fn14">
            <v-radio-group
              class="labelFont"
              v-model="radioGroup[index]"
              hide-details
            >
              <v-radio
                v-for="(response, index) in question.responses"
                :key="index"
                :label="response.text"
                :value="index"
              ></v-radio>
            </v-radio-group>
          </span>
          <v-divider class="my-6"></v-divider>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="dialog"
      persistent
      :overlay-opacity="0.9"
      max-width="375"
    >
      <a-icon
        type="close"
        class="fn16 white--text text-right"
        @click="cancel"
      />
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          block
          elevation="2"
          large
          outlined
          x-large
          v-bind="attrs"
          v-on="on"
          class="font-weight-bold text-capitalize rounded-lg bg-linear"
          style="color: white"
        >
          Submit the answer
        </v-btn>
      </template>
      <v-card class="rounded-lg pa-4" min-width="320" height="393">
        <div
          class="text-center justify-center mt-4"
          style="color: #07204b; font-size: 40px; font-weight: 700"
        >
          10/10
        </div>
        <div class="px-0">
          <v-row align="center" justify="center">
            <v-col cols="12" class="text-center mt-4">
              <div style="color: #07204b; font-size: 32px; font-weight: 600">
                You Pass the test!
              </div>
            </v-col>
            <v-col cols="2">
              <img src="@/assets/icon/CE.png" width="52" height="52" />
            </v-col>
            <v-col cols="9" class="ml-2">
              <div style="color: #1a347f" class="fn22-weight7">
                You earn 3 points
              </div>
            </v-col>
          </v-row>
        </div>
        <v-card-actions class="px-0 mt-8">
          <v-spacer></v-spacer>
          <v-btn
            block
            elevation="2"
            large
            outlined
            x-large
            v-bind="attrs"
            v-on="on"
            class="font-weight-bold rounded-lg text-capitalize bg-linear"
            style="color: white"
            @click="cancel"
          >
            Back to CE Test
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      dialog: false,
      radioGroup: {},
      quiz: "quiz",
      questionIndex: 0,
      userResponses: [],
      questions: [
        {
          no: "1. FAS ย่อมาจากอะไร",
          responses: [
            {
              text: "Feline Atopic Syndrome",
              correct: true,
            },
            {
              text: "Feline Anti-allegic Syndrome",
              correct: false,
            },
            {
              text: "Feline Animal Syndrome",
              correct: false,
            },
          ],
        },
        {
          no: "2. allergen avoidance คืออะไร",
          responses: [
            {
              text: "การหลีกเลี่ยงสารก่อภูมิแพ้",
              correct: true,
            },
            {
              text: "อาการที่แมวที่เป็นภูมิแพ้ควรหลีกเลี่ยง",
              correct: false,
            },
            {
              text: "การเลี่ยงสัตว์ที่เป็นภูมิแพ้",
              correct: false,
            },
          ],
        },
      ],

      logisticName: "",
      description: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      productOptionList: [],
    };
  },
  // async created() {
  //   var logisticdata = JSON.parse(
  //     Decode.decode(localStorage.getItem("logisticdata"))
  //   );
  //   console.log("logisticdata", logisticdata);
  //   this.id = logisticdata.id;
  //   this.logisticName = logisticdata.name;
  //   this.description = logisticdata.note;
  //   this.activeFlag = logisticdata.status;
  //   // this.getOnelogistic();
  //   this.user = JSON.parse(
  //     Decode.decode(localStorage.getItem("userBiData"))
  //   );
  //   const auth = {
  //     headers: { Authorization: `Bearer ${this.user.token}` },
  //   };
  //   const response = await this.axios.get(
  //     `${process.env.VUE_APP_API}/shippings/` + this.id,
  //     auth
  //   );
  //   console.log("getone", response.data.data);
  //   this.productOptionList = response.data.data.weightPrices;
  // },
  methods: {
    submit() {
      this.questionIndex++;
    },
    prev: function () {
      this.questionIndex--;
    },
    score: function () {
      const modeMap = {};
      const maxEl = this.userResponses[0],
        maxCount = 1;
      for (const i = 0; i < this.userResponses.length; i++) {
        const el = this.userResponses[i];
        if (modeMap[el] == null) modeMap[el] = 1;
        else modeMap[el]++;
        if (modeMap[el] > maxCount) {
          maxEl = el;
          maxCount = modeMap[el];
        }
      }
      return maxEl;
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageCE");
    },
    async addproductOptionList() {
      this.productOptionList.push({
        minWeight: this.minWeight,
        maxWeight: this.maxWeight,
        price: this.price,
      });
    },
    deleteproductOptionList() {
      if (this.productOptionList.length > 0) {
        this.productOptionList.pop();
      }
    },
    async addRow1() {
      this.productPriceList.push({
        price: null,
        // description: this.listDescription,
        companyId: this.companyId,
      });
    },
    deleteRow1() {
      if (this.productPriceList.length > 0) {
        this.productPriceList.pop();
      }
    },
    async getOnelogistic() {
      this.user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiData"))
      );
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/shippings/` + this.id,
        auth
      );
      console.log("getoneeeee", response.data);
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiData"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          name: this.logisticName,
          note: this.description,
          status: this.activeFlag,
          weightPrices: this.productOptionList,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/shippings/` + this.id,
          data,
          auth
        );
        console.log("createBrand", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขข้อมูลสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageLogistic");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.labelFont >>> label {
  font-size: 14px;
}
.bg-linear {
  background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);
}
</style>
